<template>
	<div class="app-container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('publish.import.import-bonus-header')}}</span>
				<span style="float: right">
					<el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="uploadFile">
						{{$t('general.send')}}</el-button>
					<el-button type="success" size="mini" icon="el-icon-download" plain @click.native="downloadModel"
						>{{$t('publish.import.dl-model')}}</el-button>
				</span>
			</div>
			<div class="title">
				{{$t('publish.import.bonus-import-file-header')}}
			</div>
			<el-upload ref="uploadComp" id="importFile" list-type="picture" :auto-upload='false' :on-remove="removeFile"
				class="upload-demo" :limit="1"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
				action="https://jsonplaceholder.typicode.com/posts/">
				<!-- <div slot="tip" class="el-upload__tip">Doar fisiere de tip JPG, GIF si PNG sunt acceptate</div> -->
				<el-button size="small" type="primary" icon="el-icon-document" >
					{{$t('publish.import.select-file')}}</el-button>
			</el-upload>
		</el-card>
	</div>
</template>

<script>
	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		getBonusModel,
		importBonus
	} from '@/api/publicare';
	import {
		setParams,
		debugPromise
	} from '@/utils/index';

	export default {
		components: {},
		props: ['filterQuery'],
		data() {
			return {};
		},
		methods: {
			removeFile() {
				document.getElementById('importFile').querySelector('.el-upload__input').value = '';
			},
			downloadModel() {
				getBonusModel().then((res) => {
					// const link = document.createElement('a')
					// const file = 'model_bonus.xlsx'
					// link.href = res.message.file
					// link.download = file
					// document.body.appendChild(link)
					// link.click()
					// document.body.removeChild(link)
					// link.remove()

					const linkSource =
						`data:application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.message.file}`;
					const downloadLink = document.createElement('a');
					const fileName = 'model_bonus.xlsx';

					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					document.body.appendChild(downloadLink);
					downloadLink.click();
					downloadLink.remove();

					return false;
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getBonusModel'
						}
					});
					debugPromise('getImportModel', e);
				});
			},
			uploadFile(forceInsert = false) {
				const file = document.getElementById('importFile').querySelector('.el-upload__input').files;
				if (file.length < 1) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('publish.import.no-file'),
					});
					return false;
				}

				const blob = file[0];

				const params = setParams({});
				params.append('fisier', blob);
				importBonus(params).then((res) => {
					this.resetFields();
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'importBonus'
						}
					});
					debugPromise('importFile', e);
				});
			},
			resetFields() {
				this.$refs.uploadComp.clearFiles();
			},
		},
		mounted() {},
		beforeDestroy() {
			EventBus.$off();
		},
	};
</script>

<style scoped>
	.el-message--warning {
		z-index: 9999 !important;
	}
</style>